import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
var VueScrollTo = require('vue-scrollto');
import "@lottiefiles/lottie-player";
import Aos from "aos";
import "aos/dist/aos.css";

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -65,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Aos.init({
  duration: 500,
  easing: "linear",
  offset: 120,
  once: true,
  anchorPlacement: "top-bottom",
  disable: 'mobile'
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
